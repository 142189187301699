import React, { useContext, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useHistory,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { UserContext } from './contexts';
import userConstants from './contexts/User/userConstants';

import API from './api/API';

import handler from './helpers/handler';
import storageHelper from './helpers/storageHelper';

import Checkout from './resources/checkout/components/Checkout';
import CompleteOrder from './resources/completeOrder/components/CompleteOrder';
import DeleteAccount from './resources/delete-account';
import EasyBuilder from './resources/easy-builder/components/EasyBuilder';
import FAQ from './resources/about-us/FAQ';
import Home from './resources/home/Home';
import NotFound from './resources/not-found';
import OffersPage from './resources/products-page/OffersPage';
import ProBuilder from './resources/pro-builder/components/ProBuilder';
import ProductDetail from './resources/product/ProductDetail';
import ProductsPage from './resources/products-page/ProductsPage';
import Promotions from './resources/promotions';
import RegretPolicy from './resources/regret-policy';
import UserEditData from './resources/user/UserEditData';
import UserProfile from './resources/user/UserProfile';

import { enableIndexRobots } from './data/constants';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '67px',
        '@media (max-width: 600px)': {
            marginTop: '59px',
        },
    },
}));

const GDRouter = () => {
    const [state, dispatch] = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const classes = useStyles();
    const [afterLogin, setAfterLogin] = useState(query.has('afterLogin'));
    const [afterLoginChecked, setAfterLoginChecked] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setAfterLoginChecked(false);
        if (afterLogin) {
            if (!(state.user.role)) {
                const cart = storageHelper.getUserCart();
                API.users.me()
                    .then((response) => {
                        const user = {
                            email: response.data.email,
                            role: response.data.role,
                            profileImageURL: response.data.profileImageURL,
                            shippingData: response.data.shippingData,
                        };
                        const googleLoginRedirect = localStorage.getItem('redirectAfterGoogleLogin');
                        storageHelper.clearWaitGoogleLogin();
                        dispatch({ type: userConstants.SET_USER, user });
                        setAfterLogin(false);
                        storageHelper.setUser(user);
                        if (cart?.id) API.carts.updateCart(cart);
                        else API.carts.post();

                        enqueueSnackbar(`Bienvenido, ${user.email}`, { variant: 'success' });

                        if (googleLoginRedirect) { history.push(googleLoginRedirect); }
                    })
                    .catch((error) => {
                        API.auth.logout();
                        handler.handleError({
                            error, userContextDispatch: dispatch, enqueueSnackbar,
                        });
                        dispatch({ type: userConstants.LOGOUT });
                        storageHelper.clearStorage();
                        setAfterLogin(false);
                    })
                    .finally(() => setAfterLoginChecked(true));
            }
        } else {
            storageHelper.clearWaitGoogleLogin();
            setAfterLoginChecked(true);
        }
    }, [dispatch, state.user, afterLogin, history, enqueueSnackbar]);

    return (afterLoginChecked
        ? (
            <>
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                    {!enableIndexRobots && <meta name="robots" content="noindex, nofollow" />}
                </Helmet>
                <Box className={classes.root}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/ofertas/:pageOrType?/:page?" component={OffersPage} />
                        <Route path="/productos/:pageOrType?/:page?" component={ProductsPage} />
                        <Route path="/producto/:slug" render={(props) => <ProductDetail {...props} />} />
                        <Route path="/easybuilder" component={EasyBuilder} />
                        <Route path="/probuilder/:fromPersonalize?" component={ProBuilder} />
                        <Route path="/checkout" component={Checkout} />
                        <Route path="/recoverPassword/:token" render={(props) => <Home {...props} openRecoverPassword />} />
                        <Route path="/CompleteOrder" component={CompleteOrder} />
                        <Route path="/nosotros" component={FAQ} />
                        <Route path="/promos" component={Promotions} />
                        <Route path="/politicas-de-devolucion" component={RegretPolicy} />
                        <Route path="/eliminar-cuenta" component={DeleteAccount} />
                        <Route exact path="/account/edit" component={UserEditData} />
                        <Route exact path="/account" component={UserProfile} />
                        <Route exact path="/not-found" component={NotFound} />
                        <Route path="*">
                            <Redirect to="/not-found" />
                        </Route>
                    </Switch>
                </Box>
            </>
        )
        : <CircularProgress />
    );
};

export default GDRouter;
